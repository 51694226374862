import React, { useContext, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { TrustLessContainer } from './Wallet.styled';
import { ellipsisCenter } from '@utils/address';
import { CDN_URL } from '@/configs';
import IconSVG from '@components/IconSVG';
import { ConnectWalletButton, WalletBalance } from '@/layouts/Header/Header.styled';
import { WalletContext } from '@/contexts/wallet-context';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import Text from '@components/Text';
import { formatLongAddress } from '@trustless-computer/dapp-core';
import { OverlayTrigger } from 'react-bootstrap';

const StatusMetamask = React.memo(() => {
  const { account } = useWeb3React();
  const { onConnectMetamask } = useContext(WalletContext);
  const { onDisconnect } = useContext(WalletContext);

  const ref = useRef(null);
  const [show, setShow] = React.useState(false);

  const onClickCopy = (address: string) => {
    copy(address);
    toast.success('Copied');
  };

  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const walletPopover = (
    <TrustLessContainer
      id="wallet-header"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      show={true}
    >
      <div className="wallet-btc">
        <div className="wallet-item">
          <IconSVG
            src={`${CDN_URL}/icons/ic-metamask.svg`}
            maxWidth="24"
            maxHeight="24"
          />
          <Text>{formatLongAddress(account || '')}</Text>
        </div>
        <div className="icCopy" onClick={() => onClickCopy(account || '')}>
          <IconSVG
            src={`${CDN_URL}/icons/ic-copy.svg`}
            color="white"
            maxWidth="16"
          />
        </div>
      </div>
      <div className="divider" />
      <div className="cta">
        <div className="wallet-disconnect" onClick={onDisconnect}>
          <IconSVG src={`${CDN_URL}/icons/ic-logout.svg`} maxWidth="20" />
          <Text>Disconnect</Text>
        </div>
      </div>
    </TrustLessContainer>
  );

  return account ? (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="bottom"
      overlay={walletPopover}
      container={ref}
      show={show}
    >
      <div
        className="wallet"
        ref={ref}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <WalletBalance>
          <div className="avatar">
            <div className="balance">
              <p className="container-balance">
                {ellipsisCenter({ str: account, limit: 5 })}
              </p>
            </div>
            <IconSVG
              src={`${CDN_URL}/icons/ic-metamask.svg`}
              maxWidth="24"
              maxHeight="24"
            />
          </div>
        </WalletBalance>
      </div>
    </OverlayTrigger>
  ) : (
    <ConnectWalletButton className="hideMobile" onClick={onConnectMetamask}>
      <div className="balance">
        <p className="container-balance">Connect Metamask</p>
      </div>
      <IconSVG
        src={`${CDN_URL}/icons/ic-metamask.svg`}
        maxWidth="24"
        maxHeight="24"
      />
    </ConnectWalletButton>
  );
});

export default StatusMetamask;
