import IconSVG from '@/components/IconSVG';
import Text from '@/components/Text';
import { CDN_URL, TC_WEB_URL } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';
import { getIsAuthenticatedSelector, getUserSelector } from '@/state/user/selector';
import { formatEthPrice } from '@/utils/format';
import { formatBTCPrice, formatLongAddress } from '@trustless-computer/dapp-core';
import { useWeb3React } from '@web3-react/core';
import copy from 'copy-to-clipboard';
import { useRouter } from 'next/router';
import React, { useContext, useRef, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { ConnectWalletButton, WalletBalance } from '../Header.styled';
import { TrustLessContainer } from './Wallet.styled';

const WalletHeader = () => {
  const router = useRouter();
  const { account } = useWeb3React();
  const user = useSelector(getUserSelector);
  const { onDisconnect } = useContext(WalletContext);

  const isAuthenticated = useSelector(getIsAuthenticatedSelector);

  const { btcBalance, juiceBalance } = useContext(AssetsContext);

  const [show, setShow] = useState(false);
  const handleOnMouseEnter = () => {
    setShow(true);
  };
  const handleOnMouseLeave = () => {
    setShow(false);
  };
  const ref = useRef(null);

  const goToConnectWalletPage = async () => {
    await router.push(`${ROUTE_PATH.CONNECT_WALLET}?next=${window.location.href}`);
  };

  const onClickCopy = (address: string) => {
    copy(address);
    toast.success('Copied');
  };

  const walletPopover = (
    <TrustLessContainer
      id="wallet-header"
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      show={show}
    >
      <div className="wallet-tc">
        <div className="wallet-item">
          <IconSVG
            src={`${CDN_URL}/icons/wallet_logo.svg`}
            maxWidth="24"
            maxHeight="24"
          />
          <Text>{formatLongAddress(user?.walletAddress || '')}</Text>
        </div>
        <div
          className="icCopy"
          onClick={() => onClickCopy(user?.walletAddress || '')}
        >
          <IconSVG
            src={`${CDN_URL}/icons/ic-copy.svg`}
            color="white"
            maxWidth="16"
          />
        </div>
      </div>
      <div className="divider" />
      <div className="wallet-btc">
        <div className="wallet-item">
          <IconSVG
            src={`${CDN_URL}/icons/ic-btc.svg`}
            maxWidth="24"
            maxHeight="24"
          />
          <Text>{formatLongAddress(user?.walletAddressBtcTaproot || '')}</Text>
        </div>
        <div
          className="icCopy"
          onClick={() => onClickCopy(user?.walletAddressBtcTaproot || '')}
        >
          <IconSVG
            src={`${CDN_URL}/icons/ic-copy.svg`}
            color="white"
            maxWidth="16"
          />
        </div>
      </div>
      <div className="divider" />
      <div className="cta">
        <div className="wallet-link" onClick={() => window.open(TC_WEB_URL)}>
          <IconSVG src={`${CDN_URL}/icons/ic-wallet.svg`} maxWidth="20" />
          <Text>Wallet</Text>
        </div>
        <div className="wallet-disconnect" onClick={onDisconnect}>
          <IconSVG src={`${CDN_URL}/icons/ic-logout.svg`} maxWidth="20" />
          <Text>Disconnect</Text>
        </div>
      </div>
    </TrustLessContainer>
  );

  const renderBalanceBoxHeader = () => {
    const symbolBalance = 'BVM';

    return (
      <div className="balance">
        <p className="container-balance">{formatBTCPrice(btcBalance)} BTC</p>

        {symbolBalance && (
          <>
            <span className="divider" />
            <p className="container-balance">
              {`${formatEthPrice(juiceBalance)} ${symbolBalance}`}{' '}
            </p>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {account && isAuthenticated ? (
        <>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={walletPopover}
            container={ref}
            show={show}
          >
            <div
              className="wallet"
              onClick={() => window.open(TC_WEB_URL)}
              ref={ref}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              <WalletBalance>
                {renderBalanceBoxHeader()}
                <div className="avatar">
                  <IconSVG
                    src={`${CDN_URL}/icons/wallet_logo.svg`}
                    maxWidth="24"
                    maxHeight="24"
                  />
                </div>
              </WalletBalance>
            </div>
          </OverlayTrigger>
        </>
      ) : (
        <ConnectWalletButton className="hideMobile" onClick={goToConnectWalletPage}>
          Connect Trustless Wallet
          <IconSVG
            src={`${CDN_URL}/icons/wallet_logo.svg`}
            maxWidth="24"
            maxHeight="24"
          />
        </ConnectWalletButton>
      )}
    </>
  );
};

export default WalletHeader;
