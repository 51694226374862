import px2rem from '@/utils/px2rem';
import { Tooltip } from 'react-bootstrap';
import styled, { DefaultTheme } from 'styled-components';
import Link from 'next/link';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .indicator {
    position: absolute;
    height: ${px2rem(1)};
    margin-left: -${px2rem(32)};
    margin-right: -${px2rem(32)};
    /* top: ${px2rem(80)}; */
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: ${({ theme }) => theme.colors.bg3}; */

    @media screen and (min-width: 1920px) {
      max-width: 1920px;
    }
  }

  .logo {
    z-index: 999;
    display: flex;
    align-items: center;
    flex-direction: row;

    text-decoration: none;
  }

  .logo-title {
    margin-left: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;

    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  a {
    text-decoration: unset;
  }

  .rowLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${px2rem(32)};
    position: absolute;
    left: 50%;
    transform: translateX(-47%);
  }

  .rightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${px2rem(16)};
    position: relative;

    @media screen and (min-width: 1024px) {
      :hover {
        .dropdown {
          display: block;
          z-index: 9;
        }
      }
    }

    .btnMenuMobile {
      display: none;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .hideMobile {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .dropdown {
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 100%;
    padding-top: ${px2rem(10)};
    width: ${px2rem(200)};
    display: none;

    .dropdownMenuItem {
      background: ${({ theme }) => theme.colors.bg3};
      color: ${({ theme }) => theme.colors.white};
      padding: ${px2rem(10)} ${px2rem(16)};
      font-weight: normal;
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      :hover {
        background: ${({ theme }) => theme.colors.bg4};
      }

      :first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      :last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .rowLink {
      display: none;
    }

    .wallet {
      display: none;

      &.mobile {
        display: flex;
      }
    }

    .rightContainer {
      .btnMenuMobile {
        display: flex;
      }
    }
  }
`;

const StyledLink = styled(Link)<{ active: boolean; activeColor?: string }>`
  cursor: pointer;
  font-weight: 400;
  font-size: ${px2rem(16)};
  line-height: ${px2rem(28)};
  text-decoration: none !important;
  color: ${({
    theme,
    active,
    activeColor,
  }: {
    theme: DefaultTheme;
    active: boolean;
    activeColor?: string;
  }) => (active ? activeColor || theme.colors.white : theme.colors.text2)};
  letter-spacing: -0.02em;

  :hover {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.7;
  }
`;

const Anchor = styled.a<{ active: boolean }>`
  cursor: pointer;
  font-weight: 400;
  font-size: ${px2rem(16)};
  line-height: ${px2rem(28)};
  text-decoration: none !important;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.text2};
  letter-spacing: -0.02em;

  :hover {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.7;
  }
`;

const WalletBalance = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.bg3};
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: ${px2rem(12)};
  padding: ${px2rem(12)} ${px2rem(20)};
  :hover {
    opacity: 0.8;
  }

  .balance {
    display: flex;
    align-items: center;
    gap: ${px2rem(12)};
    margin-right: 6px;

    .container-balance {
      padding: 5px;

      min-width: 65px;

      font-family: 'TitilliumWeb-SemiBold';
      text-align: center;

      background: ${({ theme }) => theme.colors.bg5};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 100px;
    }

    .divider {
      width: 1px;
      height: 16px;
      background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.bg4};
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const WalletAdress = styled(Tooltip)`
  margin-top: ${px2rem(8)};

  .tooltip-inner {
    background-color: #424242;
    color: white;
    padding: ${px2rem(6)} ${px2rem(16)};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  .tooltip-arrow::before {
    border-bottom-color: #424242;
  }
`;

const ConnectWalletButton = styled.button`
  padding: 16px 28px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  text-align: center;

  /* Neutrals/8 */

  color: #fcfcfd;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px !important;
  :disabled {
    opacity: 0.8;
  }

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Banner = styled.div`
  background: #4f43e2;
  padding: 16px;
  border-radius: 4px;
  margin-top: 12px;
`;

export {
  ConnectWalletButton,
  Wrapper,
  StyledLink,
  WalletBalance,
  WalletAdress,
  Anchor,
  Banner,
};
