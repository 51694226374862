import React from 'react';
import StatusMetamask from '@/layouts/Header/Wallet/StatusMetamask';
import StatusTrustless from '@/layouts/Header/Wallet/StatusTrustless';
import { Container } from '@/layouts/Header/Wallet/Wallet.styled';

const StatusHeader = React.memo(() => {
  return (
    <Container>
      <StatusMetamask />
      <StatusTrustless />
    </Container>
  );
});

export default StatusHeader;
