import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={48} height={48} fill="url(#a)" rx={10.231} />
    <g clipPath="url(#b)">
      <path
        fill="url(#c)"
        d="m27.688 28.08-7.92-9.68a5.978 5.978 0 0 0-4.643-2.2H12a1.2 1.2 0 0 0 0 2.4h3.125a3.587 3.587 0 0 1 2.787 1.32l7.92 9.68a5.98 5.98 0 0 0 4.643 2.2H33v3a.6.6 0 0 0 .968.474l5.4-4.2a.593.593 0 0 0 .227-.548.6.6 0 0 0-.227-.398l-5.4-4.2A.6.6 0 0 0 33 26.4v3h-2.525a3.59 3.59 0 0 1-2.787-1.32Z"
      />
      <path
        fill="url(#d)"
        d="M30.475 18.6H33v3a.6.6 0 0 0 .968.474l5.4-4.2a.6.6 0 0 0 0-.946l-5.4-4.2A.6.6 0 0 0 33 13.2v3h-2.525a5.98 5.98 0 0 0-4.644 2.2l-7.92 9.68a3.587 3.587 0 0 1-2.786 1.32H12a1.2 1.2 0 0 0 0 2.4h3.125a5.98 5.98 0 0 0 4.644-2.2l7.92-9.68a3.59 3.59 0 0 1 2.786-1.32Z"
      />
      <g opacity={0.9}>
        <path
          fill="#fff"
          d="M23.025 16.8a7.425 7.425 0 1 0 0 14.85 7.425 7.425 0 0 0 0-14.85Z"
        />
        <path
          fill="url(#e)"
          d="M23.025 16.8a7.425 7.425 0 1 0 0 14.85 7.425 7.425 0 0 0 0-14.85Zm2.44 10.783-2.44-1.283-2.44 1.283.464-2.717-1.971-1.924 2.727-.396 1.22-2.472 1.22 2.472 2.727.396L25 24.866l.466 2.717Z"
          opacity={0.9}
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={2.7}
        x2={44.4}
        y1={6.3}
        y2={45.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.018} stopColor="#6D98FF" />
        <stop offset={0.297} stopColor="#3772FF" />
        <stop offset={0.703} stopColor="#3772FF" />
        <stop offset={1} stopColor="#6D98FF" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={9.9}
        x2={18.697}
        y1={15.6}
        y2={40.479}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.276} stopColor="#fff" stopOpacity={0} />
        <stop offset={0.276} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={0.615} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={39.6}
        x2={11.208}
        y1={14.1}
        y2={11.027}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00E1E1" />
        <stop offset={0.438} stopColor="#3FFFFF" stopOpacity={0} />
        <stop offset={0.625} stopColor="#9AFFFF" stopOpacity={0} />
        <stop offset={1} stopColor="#00E1E1" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={17.625}
        x2={28.125}
        y1={18.824}
        y2={29.024}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E87100" stopOpacity={0.2} />
        <stop offset={0.438} stopColor="#FFDD84" />
        <stop offset={1} stopColor="#E87100" stopOpacity={0.6} />
      </linearGradient>
      <clipPath id="b">
        <path fill="#fff" d="M10.8 9.6h28.8v28.8H10.8z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
