import { ceilPrecised } from '@trustless-computer/dapp-core';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

export const formatEthPrice = (
  price: string | number | null,
  emptyStr?: string,
  precision = 4,
): string => {
  if (!price) return emptyStr || '-';
  return ceilPrecised(parseFloat(Web3.utils.fromWei(`${price}`, 'ether')), precision)
    .toString()
    .replace(',', '.');
};

export const formatAmount = (
  price?: string,
  canEmpty = false,
  decimals = 18,
): string => {
  let result = '';

  if (!price || price.length < 1) {
    if (canEmpty) return '--';
    else {
      return '0.0';
    }
  }
  const indexNumberBN = new BigNumber(10).pow(decimals).toNumber();
  const priceBigNumber = new BigNumber(price || '0.0');

  result = priceBigNumber.div(indexNumberBN).decimalPlaces(8).toFixed() || '0.0';

  return result;
};

type FormatAmountFactoryResult = {
  priceBN?: BigNumber;
  priceFormatedBN?: BigNumber;
  priceFormatedStr?: string;
};

export const formatAmountFactory = (
  price: string,
  decimals = 18,
  roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_DOWN,
): FormatAmountFactoryResult => {
  if (!price || price.length < 1) return {};

  const indexNumberBN = new BigNumber(10).pow(decimals || 0);
  const priceBN = new BigNumber(price || '0.0');

  const priceFormatedBN = priceBN.div(indexNumberBN);
  const priceFormatedStr = priceFormatedBN.decimalPlaces(10, roundingMode).toFixed();

  return {
    priceBN,
    priceFormatedBN,
    priceFormatedStr,
  };
};

// Ex: 123.4567890123456789
// (decimalPlaces = 6) => 123.456789
// (decimalPlaces = 10) => 123.4567890123

export const humanReadable = (price: number | string, decimalPlaces = 6): string => {
  return new BigNumber(price || 0).decimalPlaces(decimalPlaces).toFixed();
};

const toString = (num: number) => {
  new BigNumber(num).toFixed();
};

const formatUtils = {
  humanReadable,
  toString,
};

export default formatUtils;
